<template>
  <div>
    <naver-maps
      :height="propsHeight"
      :mapOptions="mapOptions"
      :initLayers="initLayers"
      @load="onLoad"
      style="margin: auto;"
    >
      <naver-info-window
        class="info-window"
        @load="onWindowLoad"
        :isOpen="info"
        :marker="marker"
      >
        <div class="info-window-container">
          <div class="title_area">
            <strong  class="name">
              {{ this.hospNm }}
            </strong>
            <span class="text2">
              
            </span>
          </div>
          <div class="address_area">
            <span class="text4">
              {{ this.address }}
            </span>
          </div>
        </div>
      </naver-info-window>
      <naver-marker
        class="marker_class"
        :lat="posY"
        :lng="posX"
        @click="onMarkerClicked"
        @load="onMarkerLoaded"
      />
      <!--<naver-circle :lat="37" :lng="127" :radius="88600"/>
      <naver-rectangle :south="36" :north="38" :west="126" :east="128"/>
      
      <naver-ellipse :bounds="{south:36,north:38,west:126,east:128}"/>
      <naver-polygon :paths="[[{lat:37,lng:127},{lat:38,lng:127},{lat:38,lng:129},{lat:37,lng:128}]]"/>
      <naver-polyline :path="[{lat:37,lng:127},{lat:38,lng:129}]"/>
      <naver-ground-overlay
        :url="'//logoproject.naver.com/img/img_about.gif'"
        :bounds="{south:36.7,north:36.9,west:126.5,east:127.5}"/>-->
    </naver-maps>
  </div>
</template>

<script>
export default {
  props: {
    posX: Number,
    posY: Number,
    address: String,
    propsWidth: Number,
    propsHeight: Number,
    hospNm: String,
  },
  name: "HelloWorld",
  data() {
    return {
      width: 600,
      height: 400,
      info: false,
      marker: null,
      count: 1,
      map: null,
      isCTT: false,
      mapOptions: {
        lat: this.posY,
        lng: this.posX,
        zoom: 15,
        zoomControl: true,
        zoomControlOptions: { position: "TOP_RIGHT" },
        mapTypeControl: true,
        center: new naver.maps.LatLng(this.posY, this.posX),
      },
      initLayers: [
        "BACKGROUND",
        "BACKGROUND_DETAIL",
        "POI_KOREAN",
        "TRANSIT",
        "ENGLISH",
        "CHINESE",
        "JAPANESE",
      ],
    };
  },
  computed: {
    /*hello() {
      return `Hello, World! × ${this.count}`;
    },*/
  },
  methods: {
    onLoad(vue) {
      this.map = vue;
    },
    onWindowLoad(that) {},
    onMarkerClicked(event) {
      this.info = !this.info;
    },
    onMarkerLoaded(vue) {
      this.marker = vue.marker;
    },
  },
  mounted() {
    //setInterval(() => this.count++, 1000);
    //console.log(this.posX + "???" + this.posY);

    //console.log(this.propsWidth + "////" + this.propsHeight);
    var marker = document.querySelector(".marker_class");
    setTimeout(() => {
       this.onMarkerClicked(marker);
    }, 1000);
  },
  updated() {},
};
</script>
<style scoped>
.info-window-container {
  padding: 10px;
  width: 200px;
  height: 100px;
}
.title_area {
  display: inline-block;
  vertical-align: top;
}

.name {
  margin-right: 6px;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -1px;
  color: #0068c3;
  line-height: 26px;
  margin-left: 3px;
}

.text2 {
  font-size: 15px;
  line-height: 19px;
  color: #8f8f8f;
  margin-left: 3px;
}

.text4 {
  font-size: 16px;
    line-height: 22px;
    color: #424242;
    margin-left: 3px;
}

.text5 {
  font-size: 16px;
  line-height: 27px;
  color: #424242;
  margin-left: 3px;
}

.address_area {
    margin-top: 3px;
}
</style>
