<template>
  <div>
    <v-container>
      <v-row justify="center">
        <!-- 준비중 페이지 -->
        <v-col
          cols="12"
          class="prepare"
          v-if="view.pageOrder === '14'"
          style="margin-top: 50px"
        >
          <v-row justify="center">
            <v-col cols="12" class="text-center a-logo">
              <!-- 임시 준비중 이미지 -->
              <img
                src="@/assets/images/thanq-logo.png"
                style="max-width: 200px"
              />
            </v-col>
            <v-col cols="12" class="text-center a-hospital a-hospital-intro">
              <!-- 병원명, 의사명 -->
              안녕하세요. <span>{{ pr.hospNm }} {{ pr.drNm }} 원장</span>입니다.
            </v-col>
            <v-col cols="12" class="text-center a-ing">
              현재 페이지 제작중입니다.
            </v-col>
            <v-col cols="12" class="a-hospital-num-print text-center">
              <div class="a-hospital-num-title a-title">전화번호</div>
              <div class="a-hospital-num-con a-contents">
                <!-- 전화번호 -->
                {{ pr.hospTel }}
              </div>
            </v-col>
            <v-col cols="12" class="a-hospital-locate-title-print text-center">
              <!-- 주소, 세부 주소 -->
              <div class="a-hospital-locate-title a-title">주소</div>
              <div class="a-hospital-locate-con a-contents">
                {{ pr.addr }} {{ pr.addrDtl }}
              </div>
            </v-col>
            <v-col cols="12" v-if="pr.addr" class="hospital-locate">
              <v-row>
                <v-col cols="12">
                  <naver-map
                    v-if="mapLocation.posX !== 1 && loadMap"
                    v-bind:posX="mapLocation.posX"
                    v-bind:posY="mapLocation.posY"
                    v-bind:address="pr.addr + ' ' + pr.addrDtl"
                    v-bind:hospNm="pr.hospNm"
                    v-bind:propsHeight="mapSize.height"
                  ></naver-map>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <!-- 자동완성 페이지 -->
        <v-col
          cols="12"
          md="8"
          lg="8"
          xl="8"
          v-if="view.pageOrder === '13'"
          class="doctor-pr"
          id="doctor-pr"
        >
          <v-row justify="center">
            <v-col cols="12">
              <v-row align="center" class="pt-16 doctor-pr-con">
                <v-col cols="6" class="text-right pr-16 hospital-logo">
                  <!-- 로고 -->
                  <v-img
                    src="@/assets/images/thanq-logo.png"
                    max-height="100%"
                    contain
                    class="hospital-logo-img"
                  >
                  </v-img>
                </v-col>
                <v-col cols="6" class="text-h4 pl-16 hospital-name">
                  <!-- 병원명 -->
                  {{ pr.hospNm }}
                </v-col>
                <v-col cols="12" class="text-center hospital-img">
                  <!-- 병원사진 -->
                  <v-avatar
                    color="indigo lighten-5"
                    rounded="xl"
                    width="95%"
                    height="500"
                    class="pt-2 pb-2 hospital-img-con"
                  >
                    <v-img
                      :src="pr.picAttchId"
                      class="ma-5 hospital-img-view"
                      max-width="100%"
                      cover
                    >
                    </v-img>
                  </v-avatar>
                </v-col>
              </v-row>
              <v-row
                justify="end"
                style="position: relative; bottom: 100px"
                class="doctor-top-info"
              >
                <v-col cols="3" class="text-left pl-16 doctor-img">
                  <!-- 의사 프로필 사진 -->
                  <v-avatar
                    rounded="circle"
                    height="220px"
                    width="220px"
                    style="
                      border: 15px solid white;
                      z-index: 100;
                      background-color: #e0e0e0;
                    "
                    class="dr-profile-img"
                  >
                    <v-img :src="pr.drPicAttchId" max-height="100%" cover>
                    </v-img>
                  </v-avatar>
                </v-col>
                <v-col
                  cols="9"
                  class="text-left pb-10 doctor-name doctor-name-col"
                  align-self="end"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="text-h4 font-weight-bold justify-end doctor-name"
                    >
                      <span>{{ pr.drNm }} 원장</span>
                    </v-col>
                    <v-col
                      cols="12"
                      class="hospital-link"
                      v-if="pr.homepage !== null && pr.homepage !== ''"
                    >
                      <span>
                        <img
                          src="@/assets/images/icons/paperclip-solid.svg"
                          style="margin-right: 10px"
                        />
                        <span
                          style="color: #146f83; cursor: pointer"
                          @click="openHomepage"
                        >
                          {{ pr.homepage }}
                        </span>
                      </span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="print-row-up">
                <!-- v-if="keywordList[0] !== undefined" -->
                <v-col cols="12">
                  <v-list>
                    <v-list-item>
                      <v-list-item-content class="Medical-subject">
                        <v-list-item-title class="Medical-subject-title title">
                          진료과목
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <div></div>
                  <div>
                    <!-- 키워드 리스트 -->
                    <span
                      class="keyword-span"
                      v-for="(k, index) in keywordList"
                      :key="index + 'k'"
                    >
                      <v-chip
                        class="keyword-chip"
                        v-if="k !== '' && k !== null"
                        color="#146f83"
                        outlined
                      >
                        {{ k }}
                      </v-chip>
                    </span>
                  </div>
                </v-col>
                <v-col cols="12" v-if="pr.hospIntro" class="hospital-info">
                  <div class="hospital-info-title title">병원소개</div>
                  <div class="hospital-info-con contents">
                    <!-- 병원소개 -->
                    {{ pr.hospIntro }}
                  </div>
                </v-col>
                <v-col cols="12" v-if="pr.etcConts" class="Treatment-time">
                  <div class="Treatment-time-title title">진료시간</div>
                  <!-- 진료시간 -->
                  <div
                    class="Treatment-time-con contents"
                    v-html="computedLine(pr.etcConts)"
                  />
                </v-col>
                <v-col cols="12" v-if="pr.addr" class="hospital-locate">
                  <div class="hospital-locate-title title">주소</div>
                  <!-- 병원 위치 -->
                  <div class="hospital-locate-con contents">
                    {{ pr.addr }} {{ pr.addrDtl }}
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <naver-map
                        v-if="mapLocation.posX !== 1 && loadMap"
                        v-bind:posX="mapLocation.posX"
                        v-bind:posY="mapLocation.posY"
                        v-bind:address="pr.addr + ' ' + pr.addrDtl"
                        v-bind:hospNm="pr.hospNm"
                        v-bind:propsHeight="mapSize.height"
                      ></naver-map>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6" v-if="pr.hospTel" class="hospital-num">
                  <div class="hospital-num-title title">전화번호</div>
                  <div class="hospital-num-con contents">
                    <!-- 전화번호 -->
                    {{ pr.hospTel }}
                  </div>
                </v-col>
                <v-col cols="6" v-if="pr.hospFaxNo" class="hospitla-fax">
                  <div class="hospitla-fax-title title">팩스</div>
                  <div class="hospital-fax-con contents">
                    <!-- 팩스 -->
                    {{ pr.hospFaxNo }}
                  </div>
                </v-col>
                <!-- <v-col cols="6" v-if="pr.homepage" class="hospital-num hospital-cursor" @click="openHomepage()">
                  <div class="hospital-num-title title">홈페이지</div>
                  <div class="hospital-num-con contents">
                    {{ pr.homepage }}
                  </div>
                </v-col> -->
                <v-col
                  cols="6"
                  v-if="pr.rsvUrl"
                  class="hospitla-fax hospital-cursor"
                  @click="openRevPage()"
                >
                  <div class="hospitla-fax-title title">예약페이지</div>
                  <div class="hospital-fax-con contents">
                    <!-- 예약페이지 -->
                    {{ pr.rsvUrl }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <!-- 디자인 페이지 -->
        <v-col cols="12" v-if="view.pageOrder === '10'" class="text-center">
          <!-- pr.prAttchId 디자인 주소값을 통해 서버에서 그림파일을 가져옴 -->
          <img
            id="b-type-image"
            class="b-type-image"
            src="@/assets/images/thanq_type_D.jpg"
          />
        </v-col>
        <v-col cols="12" class="text-center" style="margin-top: 50px">
          <v-btn
            elevation="0"
            dark
            width="150px"
            height="60px"
            large
            class="close-btn"
            @click="close()"
          >
            <img
              src="../../assets/images/menuIcon/prev_mini.svg"
              style="width: 35%"
            />
            닫기
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @printPr="printPrpage"
    />
  </div>
</template>

<script>
import NaverMap from "@/components/NaverMap";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import DefaultPopup from "@/components/modal/DefaultPopup";
export default {
  components: {
    NaverMap,
    DefaultPopup,
  },
  data() {
    return {
      loadMap: true,
      mapSize: {
        width: 450,
        height: 450,
      },
      mapLocation: {
        posX: 126.97701,
        posY: 37.578842,
      },
      keywordList: [
        "갑상선암",
        "두경부암",
        "침샘종양",
        "목멍울",
        "이하선종양",
        "갑상선암수술",
        "후두암수술",
        "구강암수술",
      ],
      searchList: [],
      headerOption: {
        navOrder: 1,
      },
      loadBtn: 0,
      popupSet: {},
      pr: {
        logoAttchId: require("@/assets/images/sample_logo.png"),
        hospNm: "OOO서울의원",
        hospIntro:
          "OOO의원에서는 최신의 치료설비와 차세대 시술 도입을 바탕으로 국민의 건강과 생명을 책임집니다.",
        conConts:
          "진심으로 최고의 의술을 펼칩니다. 단어하나, 문장 하나 모두 의료법을 고려합니다.",
        drPicAttchId: require("@/assets/images/sample_person.png"),
        drNm: "OOO병원장",
        drHist:
          "서울 의대 AAA의학전공\n AAA병 전문의\n~부대 군의관 근무\n수술및 시술 횟수 연간 2000건",
        doctIntro: "환자의 작은 불편함도 놓치지 않는 진심으로 소통하는 의사.",
        addr: "서울특별시 강남구 역삼동 858",
        addrDtl: "경복궁 2층(외래), 3층(입원ㆍ수술)",
        hospTel: "02-3700-3900",
        hospFaxNo: "02-3700-3901",
        etcConts:
          "평일\n오전9시-오후 6시\n(점심시간 오후 1시-2시)\n토요일\n오전9시-오후1시\n일요일·공휴일 휴진",
        mapAttchId: require("@/assets/images/sample_map.png"),
        picAttchId: require("@/assets/images/sample_hospital.jpg"),
        rsvUrl: "www.drRefer.net",
        homepage: "www.drRefer.net",
        etcPage: [
          {
            type: "블로그",
            value: "www.blog.com",
          },
          {
            type: "페이스북",
            value: "www.facebook.com",
          },
          {
            type: "트위터",
            value: "www.twitter.com",
          },
        ],
      },
      view: {
        pageOrder: this.$route.params.code,
      },
      form: {
        homepage: {
          type: ["홈페이지"],
          value: "aaaaa",
          flag: false,
        },
        tel: {
          value: "aa",
          flag: false,
          hasError: false,
          errorTxt: "*-와 숫자를 입력해주세요",
        },
      },
      isLogin: false,
    };
  },
  created() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);

    if (localStorage.getItem("token") === null) {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }

    let num = document.body.clientWidth + 17;
    // console.log(num);

    if (num > 783 && num < 992) {
      num = 500;
      this.mapSize.width = num + 200;
      this.mapSize.height = num - 50;
    } else if (num > 576 && num <= 783) {
      num = 300;
      this.mapSize.width = num + 180;
      this.mapSize.height = num - 50;
    } else if (num <= 576) {
      num = 300;
      this.mapSize.width = num + 100;
      this.mapSize.height = num - 50;
    } else if (num >= 992) {
      num = 500;
      this.mapSize.width = num + 250;
      this.mapSize.height = num;
    }
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    computedLine() {
      return (text) => {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        } else {
          return text;
        }
      };
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    close() {
      window.close();
    },
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
    this.SET_POPUP(false);
  },
  openHomepage() {
    const homepage =
      this.pr.homepage.indexOf("http://") > -1 ||
      this.pr.homepage.indexOf("https://") > -1
        ? this.pr.homepage
        : this.pr.homepage.indexOf("https://") > -1
        ? "http://" + this.pr.homepage
        : "https://" + this.pr.homepage;
    window.open(homepage);
  },
  openRevPage() {
    const rsvUrl =
      this.pr.rsvUrl.indexOf("http://") > -1 ||
      this.pr.rsvUrl.indexOf("https://") > -1
        ? this.pr.rsvUrl
        : "http://" + this.pr.rsvUrl;

    window.open(rsvUrl);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/pr";
@import "@/assets/scss/ViewPrWindow";
</style>
<style lang="scss">
.raw-html-embed {
  margin: 15px 0;
}

figure > img {
  max-width: 100%;
}
.pr-wrap.default
  .pr-context
  .back
  .container
  .hospital
  .info
  .article-wrap
  .article.hsPicrue
  .picture-wrap
  img {
  max-height: 400px !important;
  min-height: 207px !important;
  min-width: 250px !important;
}

.pr-wrap.special .pr-block .block-content.hsPicrue .picture-wrap,
.pr-wrap.special .pr-block .block-content.hsMap .picture-wrap {
  width: 100% !important;
}

.item.prepare {
  .content-empty {
    width: 570px !important;
    margin-top: 30px !important;
    h4.title {
      font-size: 26px !important;
      position: relative;
      top: 10px !important;
      margin-bottom: 10px !important;
    }
    .img-wrap {
      img {
        width: 200px;
      }
    }
    .img_map {
      width: 430px !important;
    }

    .keyword-wrap {
      min-width: 650px !important;
      margin-left: -40px !important;
      position: relative;
      top: 10px !important;
    }
    .keyword-chip {
      margin-top: 8px;
      height: 40px;
      font-size: 18px;
      margin-right: 4px;
    }
    .introduce-text {
      max-width: 600px !important;
    }
    .phone-text {
      margin-top: 20px !important;
    }
    .location-text {
      width: 570px;
      text-align: center;
      padding: 0 0 0 20px;
    }
    .location-text-in {
      font-size: 26px;
    }

    .map-area {
      margin-top: 10px;
      margin-left: -90px;
    }
    .diag-introduce,
    .phone-text,
    .location-introduce,
    .homepage-text {
      margin-left: -90px !important;
    }

    .location-introduce {
      margin-top: 10px !important;
    }
  }
}

.pr-context {
  position: relative;
  top: -30px;
}

.mapText {
  font-size: 26px;
}

.pr-wrap-type-c {
  position: relative;
}

@media screen and (min-width: 992px) {
  .ab-btb-wrap-type-a {
    position: relative;
    top: 85% !important;
  }
  .ab-btb-wrap-type-b {
    position: relative;
    top: 85% !important;
  }
  .ab-btb-wrap-type-c {
    top: 91.5% !important;
  }
  .ab-btb-wrap-type-e {
    top: 94% !important;
  }
  .ab-btb-wrap-type-d {
    top: 94.5% !important;
  }
}

@media screen and (max-width: 991px) {
  .pr-wrap.special .pr-block .pre-content h3.title {
    text-align: left !important;
  }
  .pr-block.align-center.first {
    .block-content.doctor-profile {
      margin: 10px !important;
      p {
        margin: 0px 10px 0 0 !important;
      }
    }
    .pre-content1 {
      margin: 0px !important;
    }
  }

  .pr-wrap.special .pr-block .block-content,
  .pr-wrap.special .pr-block .pre-content {
    margin: 0 50px 0px 50px !important;
    width: 100% !important;
  }

  .pr-wrap.special
    .pr-block
    .block-content
    .article
    .a-content.input-grid
    .url-list
    p {
    margin: 0px 30px 0 0 !important;
  }
  .pr-wrap.special .pr-block .pre-content h3.title {
    padding: 0 !important;
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 767px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 750px !important;
      }
    }
    .img_map {
      width: 400px !important;
    }
    .map-area {
      margin-left: -65px;
    }
    .diag-introduce,
    .phone-text,
    .location-introduce,
    .homepage-text {
      margin-left: -70px !important;
    }
  }

  .ab-btb-wrap-type-a {
    position: relative;
    top: 85% !important;
  }
  .ab-btb-wrap-type-b {
    position: relative;
    top: 85% !important;
  }
  .ab-btb-wrap-type-c {
    top: 90.5% !important;
  }
  .ab-btb-wrap-type-e {
    top: 94% !important;
  }
  .ab-btb-wrap-type-d {
    top: 93% !important;
  }
}

@media screen and (max-width: 975px) {
  .ab-btb-wrap-type-d {
    top: 93.5% !important;
  }
  .ab-btb-wrap-type-a {
    top: 85% !important;
  }
  .ab-btb-wrap-type-d {
    top: 94% !important;
  }
}

@media screen and (max-width: 783px) {
  .pr-wrap.special
    .pr-block
    .block-content.doctor-profile
    .d-pircture
    .img-wrap {
    width: 100% !important;
    img {
      margin: auto !important;
    }
  }
  .pr-wrap.default .pr-context .front .container div.title {
    h2 {
      width: 100% !important;
      text-align: center !important;
    }
    .img-wrap {
      img {
        max-width: 50% !important;
      }
    }
  }

  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    min-height: 820px !important;
    width: 604px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 580px !important;
      }
    }
    .item.prepare {
      .content-empty {
        width: 450px !important;
        margin-top: 60px !important;
        h4.title {
          font-size: 20px !important;
          position: relative;
          top: -15px !important;
          margin-bottom: 0px !important;
        }
        .img-wrap {
          img {
            width: 200px;
          }
        }
        .img_map {
          width: 330px !important;
        }
        .keyword-wrap {
          min-width: 470px !important;
          margin-left: 0px !important;
          top: -10px !important;
        }
        .keyword-chip {
          font-size: 13px;
          height: 30px;
          padding-right: 20px;
        }
        .introduce-text {
          max-width: 500px !important;
        }
        .location-introduce {
          max-width: 450px !important;
        }

        .location-text {
          width: 450px;
          text-align: center;
          padding: 0 0 0 20px;
        }
        .location-text-in {
          font-size: 20px;
          position: relative;
          top: -28px !important;
          margin-left: -22px !important;
        }
        .location-introduce {
          margin-top: -7px !important;
        }
        .map-area {
          margin-left: -20px;
        }
        .diag-introduce,
        .phone-text,
        .location-introduce,
        .homepage-text {
          margin-left: -23px !important;
        }
      }
    }
  }

  .ab-btb-wrap-type-a {
    position: relative;
    top: 81% !important;
  }
  .ab-btb-wrap-type-d {
    top: 95% !important;
  }
  .ab-btb-wrap-type-e {
    top: 95% !important;
  }
}

.close-btn {
  font-size: 24px !important;
}

@media screen and (max-width: 766px) {
  .ab-btb-wrap-type-b {
    position: relative;
    top: 80% !important;
  }
  .ab-btb-wrap-type-d {
    top: 95% !important;
  }
  .ab-btb-wrap-type-e {
    top: 95% !important;
  }
}

@media screen and (max-width: 604px) {
  .pr-wrap.default
    .pr-context
    .back
    .container
    .hospital
    .info
    .article-wrap.default {
    margin: 50px 0 0 0px !important;
    width: 100% !important;
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 550px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 540px !important;
      }
    }

    .introduce-text {
      max-width: 450px !important;
    }
  }
  .ab-btb-wrap-type-e {
    top: 95% !important;
  }
  .ab-btb-wrap-type-d {
    top: 95% !important;
  }
}

@media screen and (max-width: 576px) {
  /*
  .ab-btb-wrap {
    top: 790px !important;
  }
  .pr-wrap.default .pr-context .front .container div.title .img-wrap {
    flex: 0 0 100% !important;
    img {
    }
  }
*/
  .ab-btb-wrap-type-e {
    top: 93% !important;
  }

  .pr-wrap.special .pr-block .block-content .article p.type {
    margin: 0px !important;
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 430px !important;
    min-height: 800px !important;
    margin: auto;
    .item .img-wrap {
      img {
        max-width: 320px !important;
      }
    }
    .item.prepare {
      .content-empty {
        width: 350px !important;
        margin-top: 30px !important;
        h4.title {
          font-size: 16px !important;
          position: relative;
          top: 0px !important;
          margin-bottom: -5px !important;
        }
        .img-wrap {
          img {
            width: 200px;
          }
        }
        .img_map {
          width: 250px !important;
        }
        .keyword-wrap {
          min-width: 330px !important;
          margin-left: 0px !important;
          top: 10px !important;
        }
        .keyword-chip {
          font-size: 10px;
          height: 26px;
          padding-right: 10px;
        }

        .location-text {
          max-width: 350px;
          padding: 0 0 0 0;
          position: relative;
        }
        .location-text-in {
          font-size: 16px !important;
        }

        .location-introduce {
          max-width: 350px !important;
        }
        .phone-text {
          margin-top: 10px !important;
        }
      }
    }
    .circle-wrap {
      width: 200px !important;
    }
  }
  .mapText {
    font-size: 18px !important;
  }
  .ab-btb-wrap-type-c {
    position: relative;
    top: 92% !important;
  }
  .ab-btb-wrap-type-e {
    top: 95% !important;
  }
}
@media screen and (max-width: 360px) {
  /*
  .ab-btb-wrap {
    top: 660px !important;
  }
*/
  .pr-wrap.default .pr-context .front .container div.title {
    h2 {
      min-width: 280px !important;
      text-align: center !important;
    }
    .img-wrap {
      img {
        max-width: 50% !important;
      }
    }
  }
  ////////////////////////////////////////////////////

  .pr-wrap.default.onView .pr-context.notification {
    width: 300px !important;
    min-height: 730px !important;
    margin: auto;

    .item .img-wrap {
      img {
        max-width: 300px !important;
      }
    }
    .item.prepare {
      .content-empty {
        h4.title {
          font-size: 13px !important;
          position: relative;
          top: 17px !important;
          margin-bottom: -26px !important;
        }
        .title-map {
          margin-top: -10px !important;
        }
        .img_map {
          margin-top: 10px;
        }
        .img-wrap {
          img {
            width: 200px;
          }
        }
        .keyword-chip {
          font-size: 10px;
          height: 23px;
        }
      }
    }
    .circle-wrap {
      width: 150px !important;
    }
    .keyword-wrap {
      margin-top: 37px !important;
    }
  }
  .mapText {
    font-size: 13px !important;
  }
}

@media print {
  .print {
    zoom: 98% !important;
  }
}
</style>
<style scoped>
.divpop {
  position: fixed;
  right: 10px;
  z-index: 200;
}

.popCenter {
  top: 50%;
  transform: translateY(-50%);
}
</style>
